<template>
	<div class="h-screen flex w-full bg-img">
		<div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
			<vx-card>
				<div slot="no-body" class="full-page-bg-color">
					<div class="vx-row">
						<div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
							<img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto" />
						</div>
						<div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center">
							<div class="p-8">
								<div class="vx-card__title mb-8 text-center mb-5">
									<h4 class="mb-4">{{ $t('Verifying Registration') }}</h4>
								</div>
								<div class="is-loading large dark"></div>
							</div>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
import { getAxiosErrorMessage } from '@/lib/helper';
import authApi from '@/api/auth';

export default {
  name: 'Verify',
  data() {
    return {
    };
  },
  methods: {
    verify() {
      // Loading
      this.$vs.loading();

      const params = {
        token: this.token,
        lang: this.lang,
      };
      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title: 'Verify',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });

        // Loading
        this.$vs.loading.close();

        // Redirect to login
        this.$router.push('/login');
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'Verify',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });

        // Loading
        this.$vs.loading.close();

        // Redirect to login
        this.$router.push('/login');
      };
      authApi.verifyRegistration(params, callback, errorCallback);
    },
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
    lang() {
      return this.$route.query.lang || 'en';
    },
  },
  created() {
    this.verify();
  },
};
</script>
